import {
  Button,
  Group,
  Image,
  Text,
  Title,
  Alert,
  MantineProvider,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconArrowBigRight,
  IconCopyright,
} from "@tabler/icons-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getSecureLocalStorage, isTokenValid } from "../utilities/functions";
import styles from "../css/styles.module.css";

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { tokenExpired } = location.state || {};

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (token && isTokenValid(token)) {
      navigate("/homepage");
    }
  }, [navigate]);

  /*
  useEffect(() => {
    async function checkReferrer() {
        await axios.get("https://app.aiarticlewriter.org/check-user-entrance")
    }
    checkReferrer();
  }, []);*/

  const handleLoginButton = () => {
    navigate("/login");
  };

  const handlePricing = () => {
    navigate("/pricing");
  };

  const handleHomepageButton = () => {
    navigate("/", { state: { tokenExpired: false } });
  };

  return (
    <MantineProvider
      theme={{
        components: {
          Text: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Title: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Table: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          th: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Button: {
            styles: (theme, params) => ({
              root: {
                fontFamily: "Raleway",
              },
            }),
          },
        },
      }}
    >
      <div>
        {tokenExpired ? (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Error!"
            color="red"
          >
            Your session is expired, please log in to the system again!
          </Alert>
        ) : (
          <div></div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80%",
            marginLeft: "10%", // Cut 10% from the left side
            marginRight: "10%", // Cut 10% from the right side
            height: "100%",
            paddingTop: window.innerWidth < 768 ? "6%" : "3%",
          }}
        >
          <Group onClick={handleHomepageButton} style={{ cursor: "pointer" }}>
            <Image
              maw={window.innerWidth < 768 ? 35 : 60}
              mx="auto"
              radius="md"
              src={require("../../images/logo.png")}
            />
            <Text
              style={{
                fontSize: window.innerWidth < 768 ? "1.2rem" : "27px",
                fontWeight: "700",
              }}
            >
              ArticleWriter
            </Text>
          </Group>
          <Group>
          {/*
            <div>
              <Button
                style={{
                  background: "transparent",
                  padding: "0px",
                  fontSize: window.innerWidth < 700 ? 13 : 16,
                  fontWeight: "300",
                }}
                mr={window.innerWidth < 768 ? "0px" : "lg"}
                //onClick={handlePricing}
              >
                Pricing
              </Button>
            </div>*/}
            <Button
              style={{
                color: "black",
                paddingLeft: window.innerWidth < 700 ? "8px" : "1.125rem",
                paddingRight: window.innerWidth < 700 ? "8px" : "1.125rem",
                fontSize: window.innerWidth < 700 ? 13 : 16,
              }}
              variant="gradient"
              gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
              onClick={handleLoginButton}
            >
              LOGIN
            </Button>
          </Group>
        </div>
        <div className={styles.waveBorder}></div>
        <div
          style={{
            display: "flex",
            backgroundColor: "#2C5364",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: window.innerWidth < 700 ? "7%" : "2%",
            gap: "7%",
          }}
        >
          <Image
            maw={window.innerWidth < 700 ? 160 : 240}
            radius="md"
            src={require("../../images/Landingpage1.png")}
          />
          <Title
            variant="gradient"
            gradient={{ from: "#2CFFA1", to: "#2DB6FF" }}
            style={{
              width: "45%",
              marginTop: window.innerWidth < 700 ? "13%" : "2%",
              fontSize: window.innerWidth < 700 ? "1.2rem" : "2.125rem",
            }}
          >
            Easily create your article with the help of artificial intelligence!
          </Title>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "3px solid #71d8ce",
          }}
        >
          <Title
            style={{
              width: "70%",
              marginTop: "3%",
              fontSize: window.innerWidth < 768 ? "1.4rem" : "50px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Unleash the Power of AI: Your Ultimate Article Creation Solution
          </Title>
        </div>

        <div
          style={{
            width: "70%",
            marginLeft: "auto", // Center horizontally by setting left margin to auto
            marginRight: "auto", // Center horizontally by setting right margin to auto
            marginTop: "3%",
            alignItems: "center",
            fontSize: window.innerWidth < 768 ? "1rem" : "1.2rem",
            display: window.innerWidth < 768 ? "block" : "flex",
            justifyContent: "center",
            gap: "7%",
          }}
        >
          <div
            style={{
              backgroundImage: "linear-gradient(to right, #355667, #16222a)",
              width: window.innerWidth < 768 ? "100%" : "70%",
              borderRadius: "50px",
            }}
          >
            <Text
              style={{
                fontWeight: "200",
                paddingTop: "4%",
                paddingBottom: "4%",
                paddingRight: "4%",
                width: "80%",
              }}
            >
              With our advanced technology, you can effortlessly generate
              high-quality, engaging articles on any topic. Our AI system
              analyzes vast amounts of data, extracts insights, and synthesizes
              information to deliver well-structured content that meets your
              specific needs. Say goodbye to time-consuming research and
              writing—experience the power of AI and streamline your content
              creation process today.
            </Text>
          </div>
          <Image
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
            mt={window.innerWidth < 768 ? "xl" : "0px"}
            maw={window.innerWidth < 768 ? 160 : 240}
            radius="md"
            src={require("../../images/Landingpage2.png")}
          />
        </div>
        <div
          style={{
            marginTop: window.innerWidth < 768 ? "10%" : "3%",
            marginBottom: "3%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "70%",
          }}
        >
          <Button
            style={{
              color: "black",
              fontSize: window.innerWidth < 768 ? "18px" : "22px",
            }}
            variant="gradient"
            gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
            onClick={handleLoginButton}
          >
            Try Now!
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: window.innerWidth < 768 ? "5%" : "2%",
            gap: "7%",
            borderTop: "3px solid #71d8ce",
            borderBottom: "3px solid #71d8ce",
            paddingTop: window.innerWidth < 768 ? "5%" : "2%",
            paddingLeft: window.innerWidth < 768 ? "5%" : "0px",
            paddingRight: window.innerWidth < 768 ? "5%" : "0px",
          }}
        >
          <Image
            maw={240}
            radius="md"
            src={require("../../images/Landingpage3.png")}
          />

          <IconArrowBigRight size={80} color="white" />
          <Image
            maw={240}
            radius="md"
            src={require("../../images/Landingpage4.png")}
          />
        </div>
        <div
          style={{
            width: "70%",
            marginLeft: "auto", // Center horizontally by setting left margin to auto
            marginRight: "auto", // Center horizontally by setting right margin to auto
            marginTop: window.innerWidth < 768 ? "10%" : "3%",
            alignItems: "center",
            fontSize: window.innerWidth < 768 ? "1rem" : "1.2rem",
            display: window.innerWidth < 768 ? "block" : "flex",
            justifyContent: "center",
            gap: "7%",
          }}
        >
          <div
            style={{
              marginRight: "10%",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: window.innerWidth < 768 ? "1.3rem" : "27px",
                paddingBottom: "3%",
              }}
            >
              Effortless Bulk Article Creation
            </Text>
            <Text
              style={{
                fontWeight: "normal",
                fontSize: window.innerWidth < 768 ? "1.1rem" : "22px",
                paddingBottom: "3%",
              }}
            >
              Generate multiple articles simultaneously with AI-Powered
              efficiency.
            </Text>
            <Text
              style={{
                fontWeight: "200",
                fontSize: window.innerWidth < 768 ? "1rem" : "20px",
              }}
            >
              Our innovative solution harnesses the power of AI to revolutionize
              content creation, enabling you to effortlessly produce numerous
              articles at once, saving time and ensuring high-quality output.
            </Text>
          </div>

          <Image
            maw={520}
            radius="md"
            mt={window.innerWidth < 768 ? "xl" : "0px"}
            src={require("../../images/Landingpage5.png")}
          />
        </div>
        <div
          style={{
            marginLeft: "auto", // Center horizontally by setting left margin to auto
            marginRight: "auto", // Center horizontally by setting right margin to auto
            marginTop: window.innerWidth < 768 ? "10%" : "4%",
            alignItems: "center",
            fontSize: window.innerWidth < 768 ? "1rem" : "1.2rem",
            display: window.innerWidth < 768 ? "block" : "flex",
            justifyContent: "center",
            gap: "7%",
          }}
        >
          <Image
            maw={560}
            radius="md"
            src={require("../../images/Landingpage6.png")}
          />
          <div
            style={{
              width: window.innerWidth < 768 ? "70%" : "30%",
              marginLeft: window.innerWidth < 768 ? "auto" : "0px",
              marginRight: window.innerWidth < 768 ? "auto" : "0px",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: window.innerWidth < 768 ? "1.3rem" : "27px",
                paddingBottom: "3%",
              }}
            >
              Language & Tone Customization
            </Text>
            <Text
              style={{
                fontWeight: "normal",
                fontSize: window.innerWidth < 768 ? "1.1rem" : "22px",
                paddingBottom: "3%",
              }}
            >
              Select language and tone for tailored article generation.
            </Text>
            <Text
              style={{
                fontWeight: "200",
                fontSize: window.innerWidth < 768 ? "1rem" : "20px",
              }}
            >
              Empower yourself with the ability to choose the language and tone
              that aligns with your brand's voice, ensuring each generated
              article resonates perfectly with your audience.
            </Text>
          </div>
        </div>
        <div
          style={{
            width: "70%",
            marginLeft: "auto", // Center horizontally by setting left margin to auto
            marginRight: "auto", // Center horizontally by setting right margin to auto
            marginTop: window.innerWidth < 768 ? "10%" : "4%",
            alignItems: "center",
            fontSize: window.innerWidth < 768 ? "1rem" : "1.2rem",
            display: window.innerWidth < 768 ? "block" : "flex",
            justifyContent: "center",
            gap: "7%",
          }}
        >
          <div
            style={{
              marginRight: "10%",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: window.innerWidth < 768 ? "1.3rem" : "27px",
                paddingBottom: "3%",
              }}
            >
              Training with Other Contents
            </Text>
            <Text
              style={{
                fontWeight: "normal",
                fontSize: window.innerWidth < 768 ? "1.1rem" : "22px",
                paddingBottom: "3%",
              }}
            >
              Refine content generation by training with your URLs or top Google
              results.
            </Text>
            <Text
              style={{
                fontWeight: "200",
                fontSize: window.innerWidth < 768 ? "1rem" : "20px",
              }}
            >
              Take control of content quality by training the article generator
              with URLs you provide or top Google search results, to match your
              desired style and relevance.
            </Text>
          </div>

          <Image
            maw={860}
            mt={window.innerWidth < 768 ? "xl" : "0px"}
            radius="md"
            src={require("../../images/Landingpage7.png")}
          />
        </div>
        <div
          style={{
            marginLeft: "auto", // Center horizontally by setting left margin to auto
            marginRight: "auto", // Center horizontally by setting right margin to auto
            marginTop: window.innerWidth < 768 ? "10%" : "4%",
            alignItems: "center",
            fontSize: window.innerWidth < 768 ? "1rem" : "1.2rem",
            display: window.innerWidth < 768 ? "block" : "flex",
            justifyContent: "center",
            gap: "7%",
          }}
        >
          <Image
            style={{
              marginLeft: window.innerWidth < 768 ? "auto" : "0px",
              marginRight: window.innerWidth < 768 ? "auto" : "0px",
            }}
            maw={window.innerWidth < 768 ? 300 : 560}
            radius="md"
            src={require("../../images/Landingpage8.png")}
          />
          <div
            style={{
              width: window.innerWidth < 768 ? "70%" : "30%",
              marginLeft: window.innerWidth < 768 ? "auto" : "0px",
              marginRight: window.innerWidth < 768 ? "auto" : "0px",
              marginTop: window.innerWidth < 768 ? "10%" : "0px",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: window.innerWidth < 768 ? "1.3rem" : "27px",
                paddingBottom: "3%",
              }}
            >
              Publish Your Article on WordPress
            </Text>
            <Text
              style={{
                fontWeight: "normal",
                fontSize: window.innerWidth < 768 ? "1.1rem" : "22px",
                paddingBottom: "3%",
              }}
            >
              Automate WordPress Publishing by Connecting Your Account.
            </Text>
            <Text
              style={{
                fontWeight: "200",
                fontSize: window.innerWidth < 768 ? "1rem" : "20px",
              }}
            >
              Simplify your workflow by linking your WordPress account to our
              platform, empowering you to effortlessly publish generated content
              directly to your WordPress site, saving time and enhancing
              convenience.
            </Text>
          </div>
        </div>

        <div
          style={{
            marginTop: window.innerWidth < 768 ? "10%" : "3%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: "1%",
            display: window.innerWidth < 768 ? "block" : "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "70%",
            textAlign: "justify",
            fontSize: window.innerWidth < 768 ? "0.8rem" : "1rem",
            color: "white",
            fontWeight: "100",
          }}
        >
          Do you have any questions? Email to
          <Text
            style={{
              fontWeight: "bold",
              marginLeft: window.innerWidth < 768 ? "0px" : "4px",
            }}
          >
            serbay@aiarticlewriter.org
          </Text>
        </div>

        <div
          style={{
            marginTop: window.innerWidth < 768 ? "10%" : "3%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: "2%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "70%",
            textAlign: "justify",
            fontSize: window.innerWidth < 768 ? "0.8rem" : "1rem",
          }}
        >
          <IconCopyright color="white" />
          <Text color="gray" style={{ marginLeft: "0.5%", fontWeight: "100" }}>
            ArticleWriter. All rights reserved.
          </Text>
        </div>
      </div>
    </MantineProvider>
  );
};

export default LandingPage;
