import {
  Loader,
  Space,
  Table,
  Text,
  Button,
  Modal,
  Grid,
  LoadingOverlay,
  Checkbox,
  Select,
  MantineProvider,
  TextInput,
  SelectItemProps,
} from "@mantine/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { Content } from "../models/Content";
import {
  IconCircleCheck,
  IconCircleX,
  IconExclamationCircle,
  IconQuestionMark,
  IconTrash,
} from "@tabler/icons-react";
import {
  getSecureLocalStorage,
  isTokenValid,
  clearSecureLocalStorage,
} from "../utilities/functions";
import { useNavigate, useParams } from "react-router-dom";

const GeneratedContents = () => {
  const [contents, setContents] = useState<Content[]>([]);
  const [wordPressContentURLs, setWordPressContentURLs] = useState<string[]>(
    []
  );
  const [userWordpressAccounts, setUserWordpressAccounts] = useState<string[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [currentContent, setCurrentContent] = useState<string>("");
  const [errorModalContent, setErrorModalContent] = useState<string>(
    "An error has occured. Please try again!"
  );
  const [currentUserWpAccount, setCurrentUserWpAccount] = useState<string>("");
  const [loadingWarningText, setLoadingWarningText] = useState<string>("");
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [openWordpressConfiguringModal, setOpenWordpressConfiguringModal] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const [selectContentsClicked, setSelectContentsClicked] =
    useState<boolean>(false);

  const [openAddWordpressAccountModal, setOpenAddWordpressAccountModal] =
    useState<boolean>(false);
  const [customDomainWpURL, setCustomDomainWpURL] = useState<string>("");
  const [customDomainWpAdminUsername, setCustomDomainWpAdminUsername] =
    useState<string>("");
  const [customDomainWpPassword, setCustomDomainWpPassword] =
    useState<string>("");
  const [isCustomDomainWpIsAdded, setIsCustomDomainWpIsAdded] =
    useState<boolean>(false);

  const apiUrl = "https://app.aiarticlewriter.org";

  const handleWpAccountDelete = async (hoveredWpURL: string | undefined) =>  {
    const response = await axios.delete(
      `${apiUrl}/delete-wp-site-from-user`,
      {
        withCredentials: true,
        data: {
          url: hoveredWpURL,
        },
      }
    );

    const wpAccounts = response.data.wp_accounts;
    setUserWordpressAccounts(wpAccounts);
  };

  useEffect(() => {
    async function connectToWordpress() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const paramValue = urlSearchParams.get("code"); // Replace 'paramName' with your parameter's name

      if (paramValue) {
        setLoadingWarningText("Your WorPress account are being configured...");
        setOpenWordpressConfiguringModal(true);
        const params = {
          code: paramValue,
        };
        const response = await axios.post(
          `${apiUrl}/connect-to-wordpress`,
          params,
          {
            withCredentials: true,
          }
        );
        setOpenWordpressConfiguringModal(false);
        navigate("/homepage/contents");
        window.location.reload();
      }
    }
    connectToWordpress();
  }, []);

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (!token || !isTokenValid(token)) {
      clearSecureLocalStorage();
      navigate("/", { state: { tokenExpired: true } });
    }
    async function fetchData() {
      try {
        const response = await axios.get(
          `${apiUrl}/get-generated-contents`,
          {
            withCredentials: true,
          }
        );

        const allContents: Content[] = response.data.data;
        const wpAccounts = response.data.wp_accounts;
        setUserWordpressAccounts(wpAccounts);
        setContents(allContents);

        setIsLoading(false);
      } catch {}
    }
    fetchData();
  }, [navigate]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleOpenConfirmationModal = async (url: string) => {
    setCurrentContent(url);
    setOpenConfirmationModal(true);
  };

  const handleCloseConfirmationModal = async () => {
    setCurrentContent("");
    setOpenConfirmationModal(false);
  };

  const handlePostContentsOnWordPress = async () => {
    try {
      setLoadingWarningText("Your contents are being uplaoded to WordPress...");
      setOpenWordpressConfiguringModal(true);
      const params = {
        urls: wordPressContentURLs,
        blogURL: currentUserWpAccount,
      };
      const response = await axios.post(
        `${apiUrl}/post-contents`,
        params,
        {
          withCredentials: true,
        }
      );

      const allContents: Content[] = response.data.data;
      const wpAccounts = response.data.wp_accounts;
      setUserWordpressAccounts(wpAccounts);
      setContents(allContents);
      setSelectContentsClicked(false);
      setWordPressContentURLs([]);
      setOpenWordpressConfiguringModal(false);
    } catch {
      setErrorModalContent("An error has occured! Please try again later!");
      setOpenWordpressConfiguringModal(false);
      setOpenErrorModal(true);
    }
  };

  const handleAddCustomDomainWpToUser = async () => {
    try {
      setIsCustomDomainWpIsAdded(true);
      const params = {
        url: customDomainWpURL,
        adminUserName: customDomainWpAdminUsername,
        applicationPass: customDomainWpPassword,
      };
      const response = await axios.post(
        `${apiUrl}/add-custom-domain-wp-to-user`,
        params,
        {
          withCredentials: true,
        }
      );

      const wpAccounts = response.data.wp_accounts;
      setUserWordpressAccounts(wpAccounts);

      setOpenAddWordpressAccountModal(false);
      setIsCustomDomainWpIsAdded(false);
    } catch {
      // User already adds its domain before
      setErrorModalContent("You already added this domain!");
      setOpenAddWordpressAccountModal(false);
      setIsCustomDomainWpIsAdded(false);
      setOpenErrorModal(true);
    }
  };

  const handleConnectWordpress = async () => {
    window.open(
      "https://public-api.wordpress.com/oauth2/authorize?client_id=89413&redirect_uri=https://aiarticlewriter.org/homepage/contents&response_type=code",
      "_self"
    );
  };

  const handleContentDeletion = async (url: string) => {
    try {
      const response = await axios.delete(
        `${apiUrl}/delete-content`,
        {
          withCredentials: true,
          data: {
            url: url,
          },
        }
      );

      const allContents: Content[] = response.data.data;
      setContents(allContents);
      setOpenConfirmationModal(false);
    } catch {
      setErrorModalContent("An error has occured! Please try again later!");
      setOpenErrorModal(true);
    }
  };

  const handleUserCurrentWpAccountChange = (value: string) => {
    setCurrentUserWpAccount(value ? value : "");
  };

  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const displayedItems = contents.slice(startIndex, endIndex);

  const SelectItem: React.FC<SelectItemProps> = ({
    value,
    label,
    ...others
  }) => {
    return (
      <div
        {...others}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "black",
          padding: "4px 8px",
        }}
        onMouseEnter={(e) => {
          const icon = e.currentTarget.querySelector("svg");
          if (icon) {
            icon.style.display = "inline";
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onMouseLeave={(e) => {
          const icon = e.currentTarget.querySelector("svg");
          if (icon) {
            icon.style.display = "none";
          }
        }}
        onClick={() => {
          console.log("test")
        }}
      >
        <Text>{label?.toString()}</Text>
        <IconTrash
          size={44}
          color="red"
          style={{ display: "none", maxHeight: "24px", pointerEvents: "all" }}
          onMouseDown={(e) => {
            e.stopPropagation();
            handleWpAccountDelete(value)
          }}
        />
      </div>
    );
  };

  const resultTable = displayedItems.map((content: Content) => (
    <>
      <tr key={content.url}>
        {selectContentsClicked ? (
          <td style={{ wordBreak: "break-word" }}>
            <Checkbox
              onChange={(event) => {
                if (event.currentTarget.checked === true) {
                  const prevURLs = [...wordPressContentURLs];
                  prevURLs.push(content.url);
                  setWordPressContentURLs(prevURLs);
                } else {
                  let prevURLs = [...wordPressContentURLs];
                  const index = prevURLs.indexOf(content.url);
                  prevURLs.splice(index, 1);
                  setWordPressContentURLs(prevURLs);
                }
              }}
            />
          </td>
        ) : (
          <></>
        )}
        <td style={{ wordBreak: "break-word" }}>{content.title}</td>
        <td style={{ wordBreak: "break-word" }}>
          <a
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#46a9fa" }}
          >
            {content.url}
          </a>
        </td>
        <td style={{ wordBreak: "break-word" }}>
          {content.creationTime
            ? new Date(content.creationTime).toLocaleString()
            : ""}
        </td>
        <td style={{ wordBreak: "break-word", textAlign: "center" }}>
          {content.postedInWordpress ? (
            <IconCircleCheck color="green" />
          ) : (
            <IconCircleX color="red" />
          )}
        </td>
        <td style={{ wordBreak: "break-word" }}>
          <IconTrash
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenConfirmationModal(content.url)}
          />
        </td>
      </tr>
    </>
  ));

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: window.innerWidth > 724 ? "100px" : "0px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <MantineProvider
          theme={{
            components: {
              Table: {
                styles: (theme, params) => ({
                  root: {
                    color: "white",
                    fontFamily: "Raleway",
                  },
                }),
              },
              th: {
                styles: (theme, params) => ({
                  root: {
                    color: "white",
                    fontFamily: "Raleway",
                  },
                }),
              },
              Button: {
                styles: (theme, params) => ({
                  root: {
                    color: "white",
                    fontFamily: "Raleway",
                  },
                }),
              },
            },
          }}
        >
          <div
            style={{
              display: window.innerWidth > 724 ? "flex" : "block",
              alignItems: "center",
            }}
          >
            <Button
              onClick={handleConnectWordpress}
              variant="gradient"
              gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
              style={{ color: "black" }}
            >
              Connect to WordPress
            </Button>
            <Button
              ml={"md"}
              variant="gradient"
              gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
              style={{ color: "black" }}
              onClick={() => {
                setSelectContentsClicked(!selectContentsClicked);
                setWordPressContentURLs([]);
              }}
            >
              Select Contents
            </Button>

            <Button
              ml={"md"}
              variant="gradient"
              gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
              style={{ color: "black" }}
              onClick={() => {
                setOpenAddWordpressAccountModal(true);
              }}
            >
              Add Custom Wordpress Site
            </Button>

            {selectContentsClicked ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: window.innerWidth > 724 ? "0px" : "10px",
                }}
              >
                <Select
                  ml={window.innerWidth > 724 ? "md" : "0"}
                  placeholder="Select your blog URL"
                  data={userWordpressAccounts.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  itemComponent={SelectItem}
                  nothingFound="No Wordpress Account Found"
                  value={currentUserWpAccount}
                  onChange={handleUserCurrentWpAccountChange}
                />

                <Button
                  ml={"md"}
                  onClick={handlePostContentsOnWordPress}
                  disabled={
                    wordPressContentURLs.length === 0 ||
                    currentUserWpAccount.length === 0
                  }
                >
                  Post on WordPress
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>

          <Modal
            opened={openAddWordpressAccountModal}
            size={window.innerWidth < 700 ? 300 : 450}
            title=<Text
              style={{
                fontWeight: "bold",
                fontSize: window.innerWidth < 700 ? 20 : 24,
                fontFamily: "Raleway",
                color: "black",
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
              }}
            >
              Add Custom Wordpress Site
            </Text>
            onClose={() => {
              setCustomDomainWpAdminUsername("");
              setCustomDomainWpPassword("");
              setCustomDomainWpURL("");
              setOpenAddWordpressAccountModal(false);
            }}
            withCloseButton
            centered
            withinPortal={true}
          >
            <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
              <Text style={{ color: "black", textAlign: "justify" }}>
                You can add your custom-domain Wordpress sites from here.
                Firstly, you need to get an application password from admin
                dashboard of your website. You can find necessary information{" "}
                <a
                  href="https://wordpress.com/support/third-party-applications/#sites-with-plugins-installed"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  here
                </a>
                .
              </Text>
              <Space h={"md"} />
              <TextInput
                mb={"md"}
                value={customDomainWpURL}
                size={window.innerWidth < 700 ? "xs" : "md"}
                label="Website URL"
                error={
                  userWordpressAccounts.includes(customDomainWpURL)
                    ? "URL already exists!"
                    : null
                }
                placeholder="https://yourgroovysite.com"
                required
                autoComplete="off"
                onChange={(event) =>
                  setCustomDomainWpURL(event.currentTarget.value)
                }
              />
              <Space h={"md"} />
              <TextInput
                mb={"md"}
                value={customDomainWpAdminUsername}
                size={window.innerWidth < 700 ? "xs" : "md"}
                label="Admin Username"
                placeholder="Enter your website's admin username"
                required
                autoComplete="off"
                onChange={(event) =>
                  setCustomDomainWpAdminUsername(event.currentTarget.value)
                }
              />
              <Space h={"md"} />
              <TextInput
                mb={"md"}
                value={customDomainWpPassword}
                type="password"
                size={window.innerWidth < 700 ? "xs" : "md"}
                label="Application Password"
                placeholder="Enter your application's password"
                required
                autoComplete="off"
                onChange={(event) =>
                  setCustomDomainWpPassword(event.currentTarget.value)
                }
              />
              <Space h={"md"} />
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Button
                  color="green"
                  onClick={() => handleAddCustomDomainWpToUser()}
                  disabled={
                    customDomainWpAdminUsername === "" ||
                    customDomainWpPassword === "" ||
                    customDomainWpURL === "" ||
                    userWordpressAccounts.includes(customDomainWpURL)
                  }
                  style={{
                    fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
                  }}
                >
                  Add{" "}
                  <LoadingOverlay
                    visible={isCustomDomainWpIsAdded}
                    overlayOpacity={0.8}
                    loaderProps={{ size: 24 }}
                  ></LoadingOverlay>
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            opened={openErrorModal}
            onClose={() => setOpenErrorModal(false)}
            withCloseButton={false}
            centered
            padding={20}
            withinPortal={true}
            style={{ backgroundColor: "red" }}
          >
            <Grid align="center" justify="center">
              <Grid.Col span={3}>
                <IconExclamationCircle color="red" size={30} />
              </Grid.Col>
              <Grid.Col span={9}>
                <Text style={{ color: "black" }}>{errorModalContent}</Text>
              </Grid.Col>
            </Grid>
          </Modal>
          <Modal
            opened={openWordpressConfiguringModal}
            onClose={() => setOpenWordpressConfiguringModal(false)}
            withCloseButton={false}
            centered
            padding={20}
            withOverlay={false}
            withinPortal={true}
          >
            <Grid align="center">
              <Grid.Col span={12}>
                <Text style={{ color: "black" }}>{loadingWarningText}</Text>
              </Grid.Col>
            </Grid>
            <Grid align="center" justify="center">
              <Loader />
            </Grid>
          </Modal>
          <div style={{ maxWidth: "50%" }}>
            <Modal
              size={window.innerWidth < 700 ? "xs" : "md"}
              opened={openConfirmationModal}
              onClose={() => setOpenConfirmationModal(false)}
              centered
              withinPortal={true}
            >
              <Grid align="center" justify="center">
                <Grid.Col span={3}>
                  <IconQuestionMark size={30} />
                </Grid.Col>
                <Grid.Col span={9}>
                  <Text style={{ color: "black" }}>
                    Are you sure to delete the content?
                  </Text>
                </Grid.Col>
                <Grid justify="">
                  <Grid.Col span="content">
                    <Button
                      onClick={() => handleContentDeletion(currentContent)}
                      disabled={isLoading}
                    >
                      Yes
                      <LoadingOverlay
                        visible={isLoading}
                        overlayOpacity={0.8}
                        loaderProps={{ size: 24 }}
                      ></LoadingOverlay>
                    </Button>
                  </Grid.Col>
                  <Grid.Col span="content">
                    <Button
                      onClick={handleCloseConfirmationModal}
                      disabled={isLoading}
                    >
                      No
                    </Button>
                  </Grid.Col>
                </Grid>
              </Grid>
            </Modal>
          </div>
          {contents.length > 0 ? (
            <>
              <Space h={"md"} />
              <div>
                <Table
                  style={{
                    tableLayout: "fixed",
                    borderBottomColor: "#ccc",
                    borderBottomWidth: "0.01em",
                    borderBottomStyle: "solid",
                  }}
                >
                  <colgroup>
                    {selectContentsClicked ? (
                      <col style={{ width: "5%" }} />
                    ) : (
                      <></>
                    )}
                    <col style={{ width: "25%" }} />
                    <col style={{ width: "30%" }} />
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "10%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      {selectContentsClicked ? (
                        <th style={{ wordBreak: "break-word" }}></th>
                      ) : (
                        <></>
                      )}
                      <th style={{ wordBreak: "break-word", color: "white" }}>
                        Keyword
                      </th>
                      <th style={{ wordBreak: "break-word", color: "white" }}>
                        Generated URL
                      </th>
                      <th style={{ wordBreak: "break-word", color: "white" }}>
                        Creation Time
                      </th>
                      <th style={{ wordBreak: "break-word", color: "white" }}>
                        Posted on WordPress
                      </th>
                      <th
                        style={{ wordBreak: "break-word", color: "white" }}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>{resultTable}</tbody>
                </Table>
                <Space h="md" />
              </div>
              <div
                style={{
                  justifyContent: "center",
                  display: window.innerWidth < 724 ? "block" : "flex",
                  marginRight: window.innerWidth > 724 ? "120px" : "0",
                }}
              >
                {Array.from(
                  { length: Math.ceil(contents.length / 10) },
                  (_, index) => index + 1
                ).map((pageNumber) => (
                  <Button
                    size="xs"
                    variant="gradient"
                    gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                    style={{ color: "black" }}
                    key={pageNumber}
                    onClick={() => handlePageChange(pageNumber)}
                    disabled={pageNumber === currentPage}
                    ml={"md"}
                    mt={window.innerWidth < 724 ? "md" : "0px"}
                  >
                    {pageNumber}
                  </Button>
                ))}
              </div>
            </>
          ) : (
            <Text italic style={{ textAlign: "center" }}>
              You don't have any contents yet!
            </Text>
          )}
        </MantineProvider>
      )}
    </>
  );
};

export default GeneratedContents;
