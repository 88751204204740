import {
    Loader,
    Space,
    Table,
    Text,
    Button,
    Modal,
    Grid,
    LoadingOverlay,
    MantineProvider,
    TextInput,
    Flex,
    FileInput,
    Textarea,
    CloseButton,
} from "@mantine/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { Project } from "../models/Project";
import {
    IconQuestionMark,
    IconTrash,
    IconDownload
} from "@tabler/icons-react";
import {
    getSecureLocalStorage,
    isTokenValid,
    clearSecureLocalStorage,
} from "../utilities/functions";
import { useNavigate, useParams } from "react-router-dom";
import { ProjectFile } from "../models/ProjectFile";


const Projects = () => {
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [openConfirmationModal, setOpenConfirmationModal] =
        useState<boolean>(false);

    const navigate = useNavigate();

    const [openAddProjectModal, setOpenAddProjectModal] =
        useState<boolean>(false);

    const [openViewFilesModal, setOpenViewFilesModal] =
        useState<boolean>(false);

    const [projectName, setProjectName] = useState<string>("");

    const [selectedProject, setSelectedProject] = useState<Project>(); // This one is updated to update the UI with added/deleted files
    const [selectedStaticProject, setSelectedStaticProject] = useState<Project>(); // This one is not updated

    const [isProjectAdded, setIsProjectAdded] =
        useState<boolean>(false);

    const [isProjectDeleted, setIsProjectDeleted] =
        useState<boolean>(false);

    const [isProjectUpdated, setIsProjectUpdated] =
        useState<boolean>(false);

    const [newlyUploadedFiles, setNewlyUploadedFiles] = useState<File[]>([]);
    const [deletedProject, setDeletedProject] = useState<Project>();

    const apiUrl = "https://app.aiarticlewriter.org";


    useEffect(() => {
        const token = getSecureLocalStorage("item1");

        if (!token || !isTokenValid(token)) {
            clearSecureLocalStorage();
            navigate("/", { state: { tokenExpired: true } });
        }
        async function fetchData() {
            try {
                const response = await axios.get(
                    `${apiUrl}/get-projects`,
                    {
                        withCredentials: true,
                    }
                );

                const allProjects: Project[] = response.data.data;
                setProjects(allProjects);
                setIsLoading(false);
            } catch { }
        }
        fetchData();
    }, [navigate]);

    const downloadFile = async (filePath: string) => {
        try {
            const formData = new FormData();
            formData.append('filePath', filePath);

            const response = await axios.post(`${apiUrl}/download-project-file`,
                formData,
                {
                    withCredentials: true,
                }
            );

            const signedUrl = response.data.signedUrl;

            const anchor = document.createElement('a');
            anchor.href = signedUrl;
            anchor.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleOpenConfirmationModal = async (project: Project) => {
        setDeletedProject(project)
        setOpenConfirmationModal(true);
    };

    const handleCloseConfirmationModal = () => {
        setDeletedProject(undefined)
        setOpenConfirmationModal(false);
    }

    const handleFileChange = (files: File[]) => {
        const newlyUpFiles = uploadedFiles;

        files.forEach(file => {
            if (file.type === "application/pdf") {
                if (!newlyUpFiles.map(item => item.name).includes(file.name)) {
                    newlyUpFiles.push(file);
                }
            }
            else {
                alert("You can upload only PDF files!")
            }
        });

        setUploadedFiles([...newlyUpFiles]);
    };


    const handleUploadNewFile = (files: File[]) => {
        if (selectedProject) {
            const newlyUpFiles = newlyUploadedFiles;
            const selectedProjectFiles = selectedProject.files;

            files.forEach(file => {
                if (file.type === "application/pdf") {
                    if (!selectedProjectFiles.map(item => item.fileName).includes(file.name)) {
                        selectedProjectFiles.push(new ProjectFile(file.name, file.name));
                        if (!newlyUpFiles.map(item => item.name).includes(file.name)) {
                            newlyUpFiles.push(file);
                        }

                    }
                }
                else {
                    alert("You can upload only PDF files!")
                }
            });

            const updatedProject = {
                ...selectedProject,
                files: selectedProjectFiles,
            };
            setSelectedProject(updatedProject);
            setNewlyUploadedFiles(newlyUpFiles);
        }
    }


    const createProject = async () => {
        setIsProjectAdded(true);

        const formData = new FormData();
        uploadedFiles.forEach((file) => {
            formData.append('files', file);
        });
        formData.append('projectName', projectName);

        try {
            const response = await axios.post(
                `${apiUrl}/create-project`,
                formData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );

            const allProjects: Project[] = response.data.data;
            setProjects(allProjects);
        } catch (error) {
            console.error(`Error creating the project:`, error);
        }

        setIsProjectAdded(false);
        setOpenAddProjectModal(false);
        setUploadedFiles([]);
        setProjectName("");
    };


    const deleteProject = async () => {
        if (deletedProject) {
            setIsProjectDeleted(true);

            const formData = new FormData();
            formData.append('projectName', deletedProject.name);

            try {
                const response = await axios.post(
                    `${apiUrl}/delete-project`,
                    formData,
                    {
                        withCredentials: true
                    }
                );

                const allProjects: Project[] = response.data.data;
                setProjects(allProjects);
            } catch (error) {
                console.error(`Error creating the project:`, error);
            }

            setIsProjectDeleted(false);
            setOpenConfirmationModal(false);
            setUploadedFiles([]);
            setDeletedProject(undefined);
        }
    };


    const updateProject = async () => {
        if (selectedProject && selectedStaticProject) {
            setIsProjectUpdated(true);

            const initialFiles = selectedStaticProject.files;
            const updatedFiles = selectedProject.files;

            const deletedFiles: string[] = [];
            initialFiles.forEach(file => {
                if (!updatedFiles.map(item => item.fileName).includes(file.fileName)) { // If the file is not included in the final version, this means the file is deleted!
                    deletedFiles.push(file.path);
                }
            })

            const addedFiles: File[] = [];
            updatedFiles.forEach(file => {
                if (!initialFiles.map(item => item.fileName).includes(file.fileName)) { // If the file is not included in the initial version, this means the file is added!
                    addedFiles.push(newlyUploadedFiles.filter(item => item.name === file.fileName)[0]);
                }
            })

            const formData = new FormData();
            addedFiles.forEach((file) => {
                formData.append('files', file);
            });

            formData.append('projectName', selectedProject.name);

            deletedFiles.forEach((path) => {
                formData.append('deletedFiles', path);
            });


            try {
                const response = await axios.post(
                    `${apiUrl}/update-project`,
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                );

                const allProjects: Project[] = response.data.data;
                setProjects(allProjects);
            } catch (error) {
                console.error(`Error creating the project:`, error);
            }

            setNewlyUploadedFiles([]);
            setIsProjectUpdated(false);
            setOpenViewFilesModal(false);
        }
    };


    const handleRemoveFile = (file: File) => {
        const updatedFiles = uploadedFiles.filter(f => f !== file);
        setUploadedFiles(updatedFiles);
    };

    const handleDeleteFile = (file: ProjectFile) => {
        if (selectedProject) {
            const updatedFiles = selectedProject.files.filter(f => f !== file);
            const updatedProject = {
                ...selectedProject,
                files: updatedFiles,
            };
            setSelectedProject(updatedProject);
        }
    };


    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    const displayedItems = projects.slice(startIndex, endIndex);

    const resultTable = displayedItems.map((project: Project) => (
        <>
            <tr key={project.name}>
                <td style={{ wordBreak: "break-word" }}>{project.name}</td>
                <td style={{ wordBreak: "break-word" }}>
                    {project.creationTime
                        ? new Date(project.creationTime).toLocaleString()
                        : ""}
                </td>
                <td style={{ wordBreak: "break-word" }}>{project.fileCount}</td>
                <td style={{ wordBreak: "break-word", textAlign: "left" }}>
                    <Button variant="gradient"
                        gradient={{ from: "white", to: "#FFFC15", deg: 60 }} style={{ color: "black" }} onClick={() => {
                            setSelectedProject(structuredClone(project));
                            setSelectedStaticProject(structuredClone(project));
                            setOpenViewFilesModal(true);
                        }
                        }>View Files</Button>
                </td>
                <td style={{ wordBreak: "break-word" }}>
                    <IconTrash
                        color="red"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setDeletedProject(project)
                            handleOpenConfirmationModal(project);
                        }}
                    />
                </td>
            </tr>
        </>
    ));

    return (
        <>
            {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: window.innerWidth > 724 ? "100px" : "0px",
                    }}
                >
                    <Loader />
                </div>
            ) : (
                <MantineProvider
                    theme={{
                        components: {
                            Table: {
                                styles: (theme, params) => ({
                                    root: {
                                        color: "white",
                                        fontFamily: "Raleway",
                                    },
                                }),
                            },
                            th: {
                                styles: (theme, params) => ({
                                    root: {
                                        color: "white",
                                        fontFamily: "Raleway",
                                    },
                                }),
                            },
                            Button: {
                                styles: (theme, params) => ({
                                    root: {
                                        color: "white",
                                        fontFamily: "Raleway",
                                    },
                                }),
                            },
                        },
                    }}
                >
                    <div
                        style={{
                            display: window.innerWidth > 724 ? "flex" : "block",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            ml={"md"}
                            variant="gradient"
                            gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                            style={{ color: "black" }}
                            onClick={() => {
                                setOpenAddProjectModal(true);
                            }}
                        >
                            Add Project
                        </Button>
                    </div>

                    <Modal
                        opened={openViewFilesModal}
                        size={window.innerWidth < 700 ? 300 : 450}
                        title=<Text
                            style={{
                                fontWeight: "bold",
                                fontSize: window.innerWidth < 700 ? 20 : 24,
                                fontFamily: "Raleway",
                                color: "black",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                paddingTop: "10px",
                            }}
                        >
                            {selectedProject?.name} Files
                        </Text>
                        onClose={() => {
                            setOpenViewFilesModal(false);
                            setNewlyUploadedFiles([]);
                        }}
                        withCloseButton
                        centered
                        withinPortal={true}
                    >
                        <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                            <FileInput size={window.innerWidth < 700 ? "xs" : "md"}  label="Upload your PDF files" description="File names must be unique!" multiple accept="application/pdf" onChange={handleUploadNewFile}  {...{ placeholder: "Upload" }}
                            />
                            <Space h={"md"} />
                            <>
                                {selectedProject?.files.map((file, index) => (
                                    <div key={file.fileName}>
                                        <Textarea
                                            autosize
                                            disabled
                                            style={{ width: "100%", }}
                                            styles={{
                                                input: {
                                                    border: "2px solid #cccccc",
                                                    borderRadius: 0,
                                                    WebkitTextFillColor: "black",
                                                    fontFamily: "Raleway",
                                                    color: "white",
                                                    cursor: "pointer"
                                                },
                                            }}
                                            rightSection={
                                                <Flex style={{ alignItems: "center" }}>
                                                    {file.path !== file.fileName ? <IconDownload size="xl" color="gray" cursor="pointer" onClick={() => downloadFile(file.path)} /> : <></>}

                                                    <CloseButton
                                                        aria-label="Remove tag"
                                                        onClick={() => handleDeleteFile(file)}
                                                    />
                                                </Flex>
                                            }
                                        >
                                            {file.fileName}
                                        </Textarea>

                                        <Space h="sm" />
                                    </div>
                                ))}
                            </>
                            <Space h={"md"} />
                            <div style={{ justifyContent: "center", display: "flex" }}>
                                <Button
                                    color="green"
                                    onClick={updateProject}
                                    disabled={
                                        selectedProject?.files.length === 0
                                    }
                                    style={{
                                        fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
                                    }}
                                >
                                    Save{" "}
                                    <LoadingOverlay
                                        visible={isProjectUpdated}
                                        overlayOpacity={0.8}
                                        loaderProps={{ size: 24 }}
                                    ></LoadingOverlay>
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        opened={openAddProjectModal}
                        size={window.innerWidth < 700 ? 300 : 450}
                        title=<Text
                            style={{
                                fontWeight: "bold",
                                fontSize: window.innerWidth < 700 ? 20 : 24,
                                fontFamily: "Raleway",
                                color: "black",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                paddingTop: "10px",
                            }}
                        >
                            Add Project
                        </Text>
                        onClose={() => {
                            setOpenAddProjectModal(false);
                            setProjectName("");
                            setUploadedFiles([]);
                        }}
                        withCloseButton
                        centered
                        withinPortal={true}
                    >
                        <div style={{ paddingRight: "20px", paddingLeft: "20px" }}>
                            <Text style={{ color: "black", textAlign: "justify" }}>
                                You can create a project and upload its files here. Once your project is set up, you can use its files to craft and train your content while creating an article.
                            </Text>
                            <Space h={"md"} />
                            <TextInput
                                mb={"md"}
                                value={projectName}
                                size={window.innerWidth < 700 ? "xs" : "md"}
                                label="Project Name"
                                description="Project name cannot be changed later!"
                                error={
                                    projects.map(item => item.name).includes(projectName)
                                        ? "Project already exists!"
                                        : null
                                }
                                placeholder="My Project"
                                required
                                autoComplete="off"
                                onChange={(event) =>
                                    setProjectName(event.currentTarget.value)
                                }
                            />
                            <Space h={"md"} />
                            <FileInput size={window.innerWidth < 700 ? "xs" : "md"}  label="Upload your PDF files" description="File names must be unique!" multiple accept="application/pdf" onChange={handleFileChange} {...{ placeholder: "Upload" }}/>
                            <Space h={"md"} />
                            <>
                                {uploadedFiles.map((file) => (
                                    <div key={file.name}>
                                        <Textarea
                                            autosize
                                            disabled
                                            style={{ width: "100%" }}
                                            styles={{
                                                input: {
                                                    border: "2px solid #cccccc",
                                                    borderRadius: 0,
                                                    WebkitTextFillColor: "black",
                                                    fontFamily: "Raleway",
                                                    color: "white",
                                                },
                                            }}
                                            rightSection={
                                                <CloseButton
                                                    aria-label="Remove tag"
                                                    onClick={() => handleRemoveFile(file)}
                                                />
                                            }
                                        >
                                            {file.name}
                                        </Textarea>

                                        <Space h="sm" />
                                    </div>
                                ))}
                            </>
                            <Space h={"md"} />
                            <div style={{ justifyContent: "center", display: "flex" }}>
                                <Button
                                    color="green"
                                    onClick={createProject}
                                    disabled={
                                        projectName === "" ||
                                        uploadedFiles.length === 0 ||
                                        projects.map(item => item.name).includes(projectName)
                                    }
                                    style={{
                                        fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
                                    }}
                                >
                                    Add{" "}
                                    <LoadingOverlay
                                        visible={isProjectAdded}
                                        overlayOpacity={0.8}
                                        loaderProps={{ size: 24 }}
                                    ></LoadingOverlay>
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <div style={{ maxWidth: "50%" }}>
                        <Modal
                            size={window.innerWidth < 700 ? "xs" : "md"}
                            opened={openConfirmationModal}
                            onClose={() => setOpenConfirmationModal(false)}
                            centered
                            withinPortal={true}
                            title=<Text style={{ fontWeight: "bold", fontSize: "1.3rem" }}>Are you sure?</Text>
                        >
                            <Grid align="center" justify="center">
                                <Grid.Col span={3}>
                                    <IconQuestionMark size={60} />
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <Text style={{ color: "black" }}>
                                        Are you sure to delete the project <span style={{ fontWeight: "bold" }}>{deletedProject?.name}</span>? This cannot be undone.
                                    </Text>
                                </Grid.Col>
                                <Grid justify="">
                                    <Grid.Col span="content">
                                        <Button
                                            onClick={deleteProject}
                                            disabled={isProjectDeleted}
                                            style={{ background: "red" }}
                                        >
                                            Yes
                                            <LoadingOverlay
                                                visible={isProjectDeleted}
                                                overlayOpacity={0.8}
                                                loaderProps={{ size: 24 }}
                                            ></LoadingOverlay>
                                        </Button>
                                    </Grid.Col>
                                    <Grid.Col span="content">
                                        <Button
                                            onClick={handleCloseConfirmationModal}
                                            disabled={isProjectDeleted}
                                            style={{ background: "gray" }}
                                        >
                                            No
                                        </Button>
                                    </Grid.Col>
                                </Grid>
                            </Grid>
                        </Modal>
                    </div>

                    {projects.length > 0 ? (
                        <>
                            <Space h={"md"} />
                            <div>
                                <Table
                                    style={{
                                        tableLayout: "fixed",
                                        borderBottomColor: "#ccc",
                                        borderBottomWidth: "0.01em",
                                        borderBottomStyle: "solid",
                                    }}
                                >
                                    <colgroup>
                                        <col style={{ width: "25%" }} />
                                        <col style={{ width: "30%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "15%" }} />
                                        <col style={{ width: "10%" }} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th style={{ wordBreak: "break-word", color: "white" }}>
                                                Project Name
                                            </th>
                                            <th style={{ wordBreak: "break-word", color: "white" }}>
                                                Creation Time
                                            </th>
                                            <th style={{ wordBreak: "break-word", color: "white" }}>
                                                File Count
                                            </th>
                                            <th style={{ wordBreak: "break-word", color: "white" }}>
                                                Files
                                            </th>
                                            <th
                                                style={{ wordBreak: "break-word", color: "white" }}
                                            ></th>
                                        </tr>
                                    </thead>
                                    <tbody>{resultTable}</tbody>
                                </Table>
                                <Space h="md" />
                            </div>
                            <div
                                style={{
                                    justifyContent: "center",
                                    display: window.innerWidth < 724 ? "block" : "flex",
                                    marginRight: window.innerWidth > 724 ? "120px" : "0",
                                }}
                            >
                                {Array.from(
                                    { length: Math.ceil(projects.length / 10) },
                                    (_, index) => index + 1
                                ).map((pageNumber) => (
                                    <Button
                                        size="xs"
                                        variant="gradient"
                                        gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                                        style={{ color: "black" }}
                                        key={pageNumber}
                                        onClick={() => handlePageChange(pageNumber)}
                                        disabled={pageNumber === currentPage}
                                        ml={"md"}
                                        mt={window.innerWidth < 724 ? "md" : "0px"}
                                    >
                                        {pageNumber}
                                    </Button>
                                ))}
                            </div>
                        </>
                    ) : (
                        <Text italic style={{ textAlign: "center", color: "white" }}>
                            You don't have any projects yet!
                        </Text>
                    )}
                </MantineProvider>
            )}
        </>
    );
};

export default Projects;
