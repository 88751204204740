import {
  Card,
  Grid,
  Tabs,
  TextInput,
  Text,
  Button,
  Space,
  Loader,
  Container,
  Alert,
  LoadingOverlay,
  Modal,
  Table,
  MantineProvider,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconGiftCard,
  IconInfoCircle,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
  clearSecureLocalStorage,
  getSecureLocalStorage,
  isTokenValid,
} from "../utilities/functions";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserView, UserWithCoupons } from "../models/User";
import { Coupon } from "../models/Coupon";
import styles from "../css/styles.module.css";

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<UserWithCoupons>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCouponResponseWaiting, setIsCouponResponseWaiting] =
    useState<boolean>(false);
  const [isPasswordUpdating, setIsPasswordUpdating] = useState<boolean>(false);

  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const [isCouponInvalid, setIsCouponInvalid] = useState<boolean>(false);
  const [serverResponseWaiting, setServerResponseWaiting] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordConf, setPasswordConf] = useState<string>("");

  const [passErrorMsg, setPassErrorMsg] = useState<string>("");

  const [openAddCouponModal, setOpenAddCouponModal] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<string>("");

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const apiUrl = "https://app.aiarticlewriter.org";

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (!token || !isTokenValid(token)) {
      clearSecureLocalStorage();
      navigate("/", { state: { tokenExpired: true } });
    }
    async function fetchData() {
      try {
        const response = await axios.get(
          `${apiUrl}/get-user-detail`,
          {
            withCredentials: true,
          }
        );

        const userInfo: UserWithCoupons = response.data.data;
        setUser(userInfo);
        setIsLoading(false);
        setErrorOccured(false);
      } catch {
        setErrorOccured(true);
      }
    }
    fetchData();
  }, [navigate]);

  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const displayedItems = user?.coupons.slice(startIndex, endIndex);

  const resultTable = displayedItems
    ? displayedItems.map((coupon: Coupon) => (
        <>
          <tr key={coupon.value}>
            <td id="usersTable">{coupon.value}</td>
            <td id="usersTable">
              {coupon.tokenAmount
                ? coupon.tokenAmount.toLocaleString()
                : coupon.tokenAmount}
            </td>
            <td id="usersTable">{coupon.status}</td>
            <td id="usersTable">{coupon.created_at.toLocaleString()}</td>
            <td id="usersTable">{coupon.expired_at.toLocaleString()}</td>
          </tr>
        </>
      ))
    : null;

  const handleChangePassButton = async () => {
    if (isPasswordUpdating) {
      if (password !== passwordConf) {
        setPassErrorMsg("Passwords do not match!");
      } else if (password.length < 4) {
        setPassErrorMsg("Password length should be at least 4!");
      } else {
        try {
          setServerResponseWaiting(true);
          const param = {
            password: password,
          };
          await axios.post(
            `${apiUrl}/change-user-password`,
            param,
            {
              withCredentials: true,
            }
          );
        } catch {
          setServerResponseWaiting(false);
        }
        setServerResponseWaiting(false);
        handleChangePassCancelButton();
      }
    } else {
      setIsPasswordUpdating(true);
    }
  };

  const handleChangePassCancelButton = () => {
    setPassword("");
    setPasswordConf("");
    setPassErrorMsg("");
    setIsPasswordUpdating(false);
  };

  const handleCouponEntrance = async () => {
    try {
      setIsCouponResponseWaiting(true);
      const param = {
        value: coupon,
      };
      const response = await axios.post(
        `${apiUrl}/check-coupon`,
        param,
        {
          withCredentials: true,
        }
      );
      const userInfo: UserWithCoupons = response.data.data;
      setIsCouponResponseWaiting(false);
      setUser(userInfo);
      setOpenAddCouponModal(false);
      setCoupon("");
    } catch (e) {
      setCoupon("");
      setIsCouponInvalid(true);
      setIsCouponResponseWaiting(false);
      setOpenAddCouponModal(false);
    }
  };

  const handleOpenCouponModal = () => {
    setOpenAddCouponModal(true);
  };

  return (
    <MantineProvider
      theme={{
        components: {
          Text: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Title: {
            styles: (theme, params) => ({
              root: {
                color: "white",
                fontFamily: "Raleway",
              },
            }),
          },
          Button: {
            styles: (theme, params) => ({
              root: {
                color: "black",
                fontFamily: "Raleway",
              },
            }),
          },
          Card: {
            styles: (theme, params) => ({
              root: {
                padding: "3%",
                margin: window.innerWidth < 768 ? "10% auto" : "0px auto",
                border: "3px solid white",
                borderRadius: "10px",
                backgroundColor: "#18262f",
              },
            }),
          },
          
        },
      }}
    >
      {isLoading ? (
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: window.innerWidth > 724 ? "100px" : "0px",
          }}
        >
          <Loader />
        </Container>
      ) : (
        <>
          {errorOccured ? (
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title="Error!"
              color="red"
            >
              Your session is expired, please log in to the system again!
            </Alert>
          ) : (
            <div></div>
          )}
          {isCouponInvalid ? (
            <Alert
              icon={<IconAlertCircle size="1rem" />}
              title="Error!"
              color="red"
            >
              The coupon you entered is either invalid or expired!
            </Alert>
          ) : (
            <div></div>
          )}
          <Modal
            opened={openAddCouponModal}
            size={window.innerWidth < 700 ? 275 : 350}
            title=<Text
              style={{
                fontWeight: "bold",
                fontSize: window.innerWidth < 700 ? 20 : 24,
                fontFamily: "Raleway",
                color:"black"
              }}
            >
              Add Coupon
            </Text>
            onClose={() => {
              setCoupon("");
              setOpenAddCouponModal(false);
            }}
            withCloseButton
            centered
            padding={20}
            withinPortal={true}
          >
            <TextInput
              mb={"md"}
              value={coupon}
              size={window.innerWidth < 700 ? "xs" : "md"}
              label="Coupon Code"
              placeholder="Enter the coupon code"
              required
              autoComplete="off"
              onChange={(event) => setCoupon(event.currentTarget.value)}
            />
            <Space h={"md"} />
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Button
                color="green"
                onClick={() => handleCouponEntrance()}
                disabled={coupon === ""}
                style={{
                  fontSize: window.innerWidth < 700 ? "0.75rem" : "1rem",
                }}
              >
                Apply{" "}
                <LoadingOverlay
                  visible={isCouponResponseWaiting}
                  overlayOpacity={0.8}
                  loaderProps={{ size: 24 }}
                ></LoadingOverlay>
              </Button>
            </div>
          </Modal>
          <Tabs defaultValue="gallery">
            <Tabs.List>
              <Tabs.Tab value="gallery" className={styles.tabs} style={{color: "white", fontFamily: "Raleway", border: "none"}} icon={<IconInfoCircle size="0.8rem" color="white" />}>
                Information
              </Tabs.Tab>
              <Tabs.Tab value="messages" className={styles.tabs} style={{color: "white", fontFamily: "Raleway", border: "none"}} icon={<IconGiftCard size="0.8rem" color="white"/>}>
                Coupons
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="gallery" pt="xs">
              <Grid>
                <Grid.Col span={"content"}>
                  <Card
                    shadow="md"
                    padding={"1.6rem"}
                    style={{ display: "flex" }}
                  >
                    <Text style={{ fontWeight: "bold", marginRight: "4px" }}>
                      Generated Content:
                    </Text>
                    <Text>{user?.generatedContentNumber.toLocaleString()}</Text>
                  </Card>
                  <Space h="sm" />
                  <Card
                    shadow="md"
                    padding={"1.6rem"}
                    style={{ display: "flex" }}
                  >
                    <Text style={{ fontWeight: "bold", marginRight: "4px" }}>
                      Total Token:
                    </Text>
                    <Text>{user?.totalTokenAmount.toLocaleString()}</Text>
                  </Card>
                  <Space h="sm" />
                  <Card
                    shadow="md"
                    padding={"1.6rem"}
                    style={{ display: "flex" }}
                  >
                    <Text style={{ fontWeight: "bold", marginRight: "4px" }}>
                      Used Token:
                    </Text>
                    <Text>{user?.usedTokenAmount.toLocaleString()}</Text>
                  </Card>
                  <Space h="sm" />
                  <Card
                    shadow="md"
                    padding={"1.6rem"}
                    style={{ display: "flex" }}
                  >
                    <Text style={{ fontWeight: "bold", marginRight: "4px" }}>
                      Remaining Token:
                    </Text>
                    <Text>
                      {user
                        ? (
                            user?.totalTokenAmount - user?.usedTokenAmount
                          ).toLocaleString()
                        : ""}
                    </Text>
                  </Card>
                </Grid.Col>
                <Grid.Col span={"content"}>
                  <Card
                    shadow="md"
                    padding={"2rem"}
                    style={{ height: "22rem", width: "20rem" }}
                  >
                    <TextInput
                      disabled
                      value={user?.email}
                      label="Email"
                      mb={"md"}
                      type="email"
                      placeholder="Enter your email"
                      autoComplete="off"
                    />

                    <TextInput
                      disabled={!isPasswordUpdating}
                      value={isPasswordUpdating ? password : "11111111111"}
                      style={{ marginBottom: "16px" }}
                      label="Password"
                      type="password"
                      placeholder="Enter your password"
                      autoComplete="off"
                      onChange={(event) =>
                        setPassword(event.currentTarget.value)
                      }
                      error={passErrorMsg.length > 0 ? passErrorMsg : null}
                    />

                    {isPasswordUpdating ? (
                      <TextInput
                        disabled={!isPasswordUpdating}
                        value={
                          isPasswordUpdating ? passwordConf : "11111111111"
                        }
                        style={{ marginBottom: "16px" }}
                        label="Password Confirmation"
                        type="password"
                        placeholder="Enter your password"
                        autoComplete="off"
                        onChange={(event) =>
                          setPasswordConf(event.currentTarget.value)
                        }
                        error={passErrorMsg.length > 0 ? passErrorMsg : null}
                      />
                    ) : (
                      <Container></Container>
                    )}

                    <Container
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "0px",
                      }}
                    >
                      {isPasswordUpdating ? (
                        <>
                          <Button
                            onClick={handleChangePassCancelButton}
                            color="red"
                          >
                            Cancel
                          </Button>
                          <Space w={"md"} />
                        </>
                      ) : (
                        <></>
                      )}

                      <Button
                        onClick={handleChangePassButton}
                        variant="gradient"
                        gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                      >
                        {isPasswordUpdating ? "Save" : "Change Password"}
                        <LoadingOverlay
                          visible={serverResponseWaiting}
                          overlayOpacity={0.8}
                          loaderProps={{ size: 24 }}
                        ></LoadingOverlay>
                      </Button>
                    </Container>
                  </Card>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>

            <Tabs.Panel value="messages" pt="xs">
              <Container style={{ justifyContent: "center", display: "flex" }}>
                <Button
                  style={{
                    color: "black",
                    justifyContent: "center",
                  }}
                  type="submit"
                  variant="gradient"
                  gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                  onClick={handleOpenCouponModal}
                >
                  Enter Coupon
                </Button>
              </Container>
              {user?.coupons && user?.coupons.length > 0 ? (
                <>
                  <div>
                    <Space h={"xl"} />
                    <Table
                      style={{
                        tableLayout: "fixed",
                        borderBottomColor: "#ccc",
                        borderBottomWidth: "0.01em",
                        borderBottomStyle: "solid",
                      }}
                    >
                      <colgroup
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <col style={{ width: "23%" }} />
                        <col
                          style={{
                            width: window.innerWidth < 700 ? "27%" : "20%",
                          }}
                        />
                        <col
                          style={{
                            width: window.innerWidth < 700 ? "27%" : "20%",
                          }}
                        />
                        <col
                          style={{
                            width: window.innerWidth < 700 ? "20%" : "20%",
                          }}
                        />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "20%" }} />
                        <col style={{ width: "20%" }} />
                        <col
                          style={{
                            width: window.innerWidth < 700 ? "10%" : "20%",
                          }}
                        />
                      </colgroup>
                      <thead>
                        <tr>
                          <th id="usersTable">Coupon Code</th>
                          <th id="usersTable">Token Amount</th>
                          <th id="usersTable">Status</th>
                          <th id="usersTable">Created Time</th>
                          <th id="usersTable">Expired Time</th>
                        </tr>
                      </thead>
                      <tbody>{resultTable}</tbody>
                    </Table>
                    <Space h="md" />
                  </div>
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {Array.from(
                      { length: Math.ceil(user.coupons.length / 10) },
                      (_, index) => index + 1
                    ).map((pageNumber) => (
                      <Button
                        size="xs"
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        disabled={pageNumber === currentPage}
                        ml={"md"}
                      >
                        {pageNumber}
                      </Button>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <Text style={{ fontStyle: "italic", textAlign: "center" }}>
                    You do not have any coupon yet!
                  </Text>
                </>
              )}
            </Tabs.Panel>
          </Tabs>
        </>
      )}
    </MantineProvider>
  );
};

export default Profile;
