import React, { useEffect } from "react";
import {
  Card,
  TextInput,
  Button,
  Text,
  Grid,
  Title,
  Modal,
  Alert,
  Group,
  LoadingOverlay,
  Image,
} from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { User } from "../models/User";
import {
  IconCircleCheckFilled,
  IconAlertCircle,
  IconMail,
  IconLock,
  IconCopyright,
  IconMailCode,
} from "@tabler/icons-react";
import { getSecureLocalStorage, isTokenValid } from "../utilities/functions";

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const SignupPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConf, setPasswordConf] = useState<string>("");

  const [emailValid, setEmailValid] = useState<boolean>(true);
  const [passValid, setPassValid] = useState<boolean>(true);
  const [passConfValid, setPassConfValid] = useState<boolean>(true);
  const [confCodeValid, setConfCodeValid] = useState<boolean>(true);

  const [emailValidMsg, setEmailValidMsg] = useState<string>("");
  const [passValidMsg, setPassValidMsg] = useState<string>("");
  const [passConfValidMsg, setPassConfValidMsg] = useState<string>("");
  const [confCodeValidMsg, setConfCodeValidMsg] = useState<string>("");

  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");

  const [responseCode, setResponseCode] = useState<string>("");

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openAlertMsg, setOpenAlertMsg] = useState<string>("An error has occured while signing up to the system. Please check back later.");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const apiUrl = "https://app.aiarticlewriter.org";

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (token && isTokenValid(token)) {
      navigate("/homepage");
    }
  }, [navigate]);

  const handleConfirmationCode = async () => {
    try {
      let valid = true;
      if (!email.includes("@")) {
        setEmailValid(false);
        setEmailValidMsg("Invalid Email!");
        valid = false;
      }
      if (password.length < 5) {
        setPassValid(false);
        setPassValidMsg("Password length should be greater than 4!");
        valid = false;
      }
      if (password !== passwordConf) {
        setPassConfValid(false);
        setPassConfValidMsg("Passwords should match!");
        valid = false;
      }

      if (valid) {
        setEmailValid(true);
        setPassValid(true);
        setPassConfValid(true);
        setIsLoading(true);
        const params = {
          email: email,
        };
        const response = await axios.post(
          `${apiUrl}/send-confirmation-email`,
          params
        );

        const data = response.data;
        //setResponseCode(data["code"]);
        setResponseCode("1234");
        setCodeSent(true);
        setIsLoading(false);
      }
    } catch (e) {
      setOpenAlertMsg("User with given email already exists!")
      setOpenAlert(true);
      setIsLoading(false);
    }
  };

  const handleSignup = async () => {
    setIsLoading(true);
    if (responseCode.toString() === code.toString()) {
      try {
        const user = new User(email, password);
        await axios.post(`${apiUrl}/create-user`, user);

        setIsLoading(false);
        setOpenModal(true);
        await sleep(5000);
        navigate("/login");
      } catch (error: any) {
        setOpenAlertMsg("An error has occured while signing up to the system. Please check back later.")
        setOpenAlert(true);
      }
    } else {
      setConfCodeValid(false);
      setConfCodeValidMsg("Invalid Code!");
      setIsLoading(false);
    }
  };

  const navigateLogin = () => {
    navigate("/login", { replace: true });
  };

  const getFontSize = () => {
    if (window.innerWidth < 768) {
      return "1.8rem";
    }
    return "2.4rem";
  };

  const handleNavigateHomePage = () => {
    navigate("/", { state: { tokenExpired: false } });
  };

  return (
    <div
      style={{
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      {openAlert ? (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title="Error!"
          color="red"
        >
            {openAlertMsg}
        </Alert>
      ) : (
        <div></div>
      )}

      <Modal
        opened={openModal}
        onClose={() => setOpenModal(false)}
        withCloseButton={false}
        centered
      >
        <Grid align="center">
          <Grid.Col span={2}>
            <IconCircleCheckFilled color="green" size={40} />
          </Grid.Col>
          <Grid.Col span={10}>
            <Text>
              {" "}
              You successfully signed up to the system! You will be redirected
              to the login page.
            </Text>
          </Grid.Col>
        </Grid>
      </Modal>

      <div
        style={{
          borderBottom: "3px solid #71d8ce",
          paddingBottom: "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "80%",
            marginLeft: "10%", // Cut 10% from the left side
            marginRight: "10%", // Cut 10% from the right side
            paddingTop: window.innerWidth < 768 ? "8%" : "3%",
            paddingBottom: window.innerWidth < 768 ? "4%" : "0px",
          }}
        >
          <Group onClick={handleNavigateHomePage} style={{ cursor: "pointer" }}>
            <Image
              maw={window.innerWidth < 768 ? 35 : 60}
              mx="auto"
              radius="md"
              src={require("../../images/logo.png")}
            />
            <Text
              style={{
                fontSize: window.innerWidth < 768 ? "1.2rem" : "27px",
                fontFamily: "Raleway",
                fontWeight: "700",
                color: "white",
              }}
            >
              ArticleWriter
            </Text>
          </Group>
        </div>
      </div>

      <Grid justify="center">
        <Grid.Col span={8}>
          <Card
            style={{
              maxWidth: 400,
              padding: "3%",
              margin: window.innerWidth < 768 ? "10% auto" : "3% auto",
              border: "3px solid #71d8ce",
              borderRadius: "10px",
              backgroundColor: "#18262f",
            }}
          >
            <Title
              mb={"md"}
              style={{
                textAlign: "center",
                fontSize: getFontSize(),
                fontFamily: "Raleway",
                color: "white",
              }}
            >
              Sign Up
            </Title>
            <Text
              mb={"md"}
              style={{
                textAlign: "center",
                color: "white",
                fontFamily: "Raleway",
              }}
            >
              Create New Account
            </Text>
            <TextInput
              icon=<IconMail />
              mb={"md"}
              value={email}
              disabled={codeSent}
              type="email"
              placeholder="Email"
              required
              error={emailValid ? null : emailValidMsg}
              autoComplete="off"
              onChange={(event) => setEmail(event.currentTarget.value)}
            />

            <TextInput
              icon=<IconLock />
              mb={"md"}
              value={password}
              disabled={codeSent}
              type="password"
              placeholder="Password"
              required
              error={passValid ? null : passValidMsg}
              autoComplete="off"
              onChange={(event) => setPassword(event.currentTarget.value)}
            />

            <TextInput
              icon=<IconLock />
              mb={"md"}
              value={passwordConf}
              disabled={codeSent}
              type="password"
              placeholder="Password Again"
              error={passConfValid ? null : passConfValidMsg}
              required
              autoComplete="off"
              onChange={(event) => setPasswordConf(event.currentTarget.value)}
            />

            {codeSent ? (
              <TextInput
                icon=<IconMailCode />
                mb={"md"}
                value={code}
                type="number"
                error={confCodeValid ? null : confCodeValidMsg}
                placeholder="Confirmation Code"
                required
                autoComplete="off"
                onChange={(event) => setCode(event.currentTarget.value)}
              />
            ) : (
              <div></div>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px", // Add margin top for spacing
              }}
            >
              <Grid.Col span={4}>
                <Button
                  style={{
                    color: "black",
                    fontFamily: "Raleway",
                    fontSize: window.innerWidth < 700 ? 13 : 16,
                  }}
                  type="submit"
                  variant="gradient"
                  gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                  onClick={() =>
                    codeSent ? handleSignup() : handleConfirmationCode()
                  }
                >
                  Sign Up
                  <LoadingOverlay
                    visible={isLoading}
                    overlayOpacity={0.8}
                    loaderProps={{ size: 24 }}
                  ></LoadingOverlay>
                </Button>
              </Grid.Col>
            </div>

            <Grid>
              <Grid.Col span="content">
                <Text
                  mt={"sm"}
                  style={{
                    fontSize: window.innerWidth < 768 ? "14px" : "16px",
                    fontFamily: "Raleway",
                    color: "white",
                  }}
                >
                  Already have an account?
                </Text>
              </Grid.Col>
              <Grid.Col span="content">
                <Text
                  mt={"sm"}
                  style={{
                    color: "#4a54c5",
                    cursor: "pointer",
                    fontFamily: "Raleway",
                    fontSize: window.innerWidth < 768 ? "14px" : "16px",
                  }}
                  onClick={navigateLogin}
                >
                  Login
                </Text>
              </Grid.Col>
            </Grid>
          </Card>
          <div
            style={{
              marginTop: window.innerWidth < 768 ? "10%" : "3%",
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: "1%",
              display: window.innerWidth < 768 ? "block" : "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
              textAlign: "justify",
              fontSize: window.innerWidth < 768 ? "0.8rem" : "1rem",
              color: "white",
              fontWeight: "100",
            }}
          >
            Do you have any questions? Email to
            <Text
              style={{
                fontWeight: "bold",
                marginLeft: window.innerWidth < 768 ? "0px" : "4px",
                fontFamily: "Raleway",
              }}
            >
              serbay@aiarticlewriter.org
            </Text>
          </div>
        </Grid.Col>
      </Grid>
      <div
        style={{
          marginTop: window.innerWidth < 768 ? "10%" : "3%",
          marginLeft: "auto",
          marginRight: "auto",
          paddingBottom: "2%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "70%",
          textAlign: "justify",
          fontSize: window.innerWidth < 768 ? "0.8rem" : "1rem",
        }}
      >
        <IconCopyright color="white" />
        <Text
          color="gray"
          style={{
            marginLeft: "0.5%",
            fontWeight: "100",
            fontFamily: "Raleway",
            color: "white",
          }}
        >
          ArticleWriter. All rights reserved.
        </Text>
      </div>
    </div>
  );
};

export default SignupPage;
