import {
  Title,
  Text,
  Grid,
  Space,
  Card,
  TextInput,
  Paper,
  Select,
  Group,
  CloseButton,
  Button,
  Modal,
  Loader,
  Table,
  Textarea,
  Radio,
  Alert,
  MantineProvider,
  Flex,
  Checkbox,
} from "@mantine/core";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { IconAlertCircle, IconCircleCheckFilled } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import {
  clearSecureLocalStorage,
  getSecureLocalStorage,
  isTokenValid,
} from "../utilities/functions";
import { Project } from "../models/Project";

export interface GeneratedContent {
  keyword: string;
  url: string;
}

export interface GeneratedBrief {
  keyword: string;
  brief: string;
}

const HomePage = () => {
  const [model, setModel] = useState("GPT-4o-mini");
  const [language, setLanguage] = useState("English");
  const [tone, setTone] = useState("Formal");
  const [projectName, setProjectName] = useState("");
  const [projects, setProjects] = useState<{ label: string; value: string }[]>([]);
  const [wordCount, setWordCount] = useState("3000");
  const [keywords, setKeywords] = useState<string[]>([]);
  const [contentTrainingURLs, setContentTrainingURLs] = useState<string[]>([]);
  const [singleKeyword, setSingleKeyword] = useState<string>("");

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openBriefModal, setOpenBriefModal] = useState<boolean>(false);

  const [urls, setURLs] = useState<string[]>([]);
  const tableRef = useRef<HTMLTableElement>(null);
  const [contentTrainingMethod, setContentTrainingMethod] =
    useState("projectFiles");
  const [showPage, setShowPage] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);

  const [generatedBriefs, setGeneratedBriefs] = useState<GeneratedBrief[]>([]);
  const [currentBriefIndex, setCurrentBriefIndex] = useState<number>(-1);
  const [briefTextareaValue, setBriefTextareaValue] = useState<string>("");

  const navigate = useNavigate();

  const apiUrl = "https://app.aiarticlewriter.org";

  const [checkedValues, setCheckedValues] = useState({
    projectFiles: false,
    googleTopResults: false,
    usersURL: false,
  });

  const handleCheckboxChange = (key: 'projectFiles' | 'googleTopResults' | 'usersURL') => {
    setCheckedValues((prev) => {
      const updated = { ...prev, [key]: !prev[key] };
  
      if (key === 'usersURL' && updated[key]) {
        return { projectFiles: false, googleTopResults: false, usersURL: true };
      }
      if (key === 'projectFiles' || key === 'googleTopResults') {
        return {
          ...updated,
          usersURL: false,
        };
      }
      return updated;
    });
  };

  const getContentTrainingMethod = () => {
    if(checkedValues.usersURL) {
      return "manualURLs";
    }

    if(checkedValues.projectFiles && checkedValues.googleTopResults) {
      return "topGoogleResultsANDuserProjects";
    }

    if(checkedValues.projectFiles) {
      return "userProjects";
    }

    if(checkedValues.googleTopResults) {
      return "topGoogleResults";
    }

    return "";
  }

  useEffect(() => {
    const token = getSecureLocalStorage("item1");

    if (!token || !isTokenValid(token)) {
      clearSecureLocalStorage();
      navigate("/", { state: { tokenExpired: true } });
    }
    async function fetchData() {
      try {
        const response = await axios.get(
          `${apiUrl}/get-projects`,
          {
            withCredentials: true,
          }
        );

        const allProjects: Project[] = response.data.data;
        setProjects(allProjects.map(item => ({
          label: item.name,
          value: item.name
        })));
        if (allProjects.length > 0) {
          setProjectName(allProjects[0].name);
        }
        setShowPage(true);
      } catch { }
    }
    fetchData();
  }, [navigate]);

  const handleModelChange = (value: string) => {
    setModel(value ? value : "");
  };

  const handleAddProjectPressed = () => {
    navigate("/homepage/projects", { state: { tokenExpired: true } });
  }

  const handleLanguageChange = (value: string) => {
    setLanguage(value ? value : "");
  };

  const handleToneChange = (value: string) => {
    setTone(value ? value : "");
  };

  const handleProjectChange = (value: string) => {
    setProjectName(value ? value : "");
  };

  const handleSingleKeywordChange = (value: string) => {
    setSingleKeyword(value ? value : "");
  };

  const handleSingleKeywordContentTrainingURLsChange = (
    url: string,
    keyword: string
  ) => {
    const index = keywords.indexOf(keyword);
    let prevValues = [...contentTrainingURLs];
    prevValues[index] = url;
    setContentTrainingURLs(prevValues);
  };

  const handleReturnPressed = () => {
    if (singleKeyword.length > 2 && !keywords.includes(singleKeyword)) {
      let prevValues = [...keywords];
      prevValues.push(singleKeyword);
      setKeywords(prevValues);
      setSingleKeyword("");
    }
  };

  const handleClosePressed = (keyword: string) => {
    const index = keywords.indexOf(keyword);
    const updatedArray = [...keywords];
    const updatedContentTrainingURLs = [...contentTrainingURLs];
    updatedArray.splice(index, 1);
    updatedContentTrainingURLs.splice(index, 1);
    setContentTrainingURLs(updatedContentTrainingURLs);
    setKeywords(updatedArray);
  };

  const handleGenerateContent = async () => {
    setOpenModal(true);
    setURLs([]);
    let contentURLs = contentTrainingURLs;
    if (contentTrainingURLs.length === 0) {
      contentURLs = Array(keywords.length).fill("");
    }

    const trainingMethod = getContentTrainingMethod();

    let requestParams = {
      briefs: generatedBriefs,
      model: model,
      language: language,
      tone: tone,
      wordCount: wordCount,
      contentTrainingURLs: contentURLs,
      projectName: projectName,
      trainingMethod: trainingMethod
    };

    try {
      const response = await axios.post(
        `${apiUrl}/generate-content`,
        requestParams,
        {
          withCredentials: true,
        }
      );

      const responseData = response.data;

      const returnedURLS = responseData.map((item: GeneratedContent) => item.url);
      const returnedKeywords = responseData.map(
        (item: GeneratedContent) => item.keyword
      );

      setKeywords(returnedKeywords);
      setURLs(returnedURLS);
      setOpenModal(false);
    } catch (error: any) {
      setOpenModal(false);
      setOpenAlert(true);
    }
  };


  const handleGenerateBrief = async () => {
    setOpenModal(true);
    setGeneratedBriefs([]);
    let contentURLs = contentTrainingURLs;
    if (contentTrainingURLs.length === 0) {
      contentURLs = Array(keywords.length).fill("");
    }

    const trainingMethod = getContentTrainingMethod();

    let requestParams = {
      keywords: keywords,
      model: model,
      language: language,
      tone: tone,
      wordCount: wordCount,
      contentTrainingURLs: contentURLs,
      projectName: projectName,
      trainingMethod: trainingMethod
    };
    
    try {
      const response = await axios.post(
        `${apiUrl}/generate-brief`,
        requestParams,
        {
          withCredentials: true,
        }
      );

      const responseData = response.data;

      const mappedData: GeneratedBrief[] = responseData.map((item: GeneratedBrief) => ({
        keyword: item.keyword,
        brief: item.brief,
      }));

      setGeneratedBriefs(mappedData);
      setOpenModal(false);
    } catch (error: any) {
      setOpenModal(false);
      setOpenAlert(true);
    }
  };


  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [urls]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [openAlert]);

  const getFontSize = () => {
    if (window.innerWidth < 768) {
      return "1.8rem"; // Adjust the font size for mobile devices
    }
    return "2.4rem"; // Default font size for larger screens
  };

  const getContentWidth = () => {
    if (window.innerWidth < 768) {
      return "90%"; // Adjust the font size for mobile devices
    }
    return "45%"; // Default font size for larger screens
  };

  const getExplanationSize = () => {
    if (window.innerWidth < 768) {
      return "1rem"; // Adjust the font size for mobile devices
    }
    return "1.2rem"; // Default font size for larger screens
  };

  const getAdvantagesSize = () => {
    if (window.innerWidth < 768) {
      return "0.8rem"; // Adjust the font size for mobile devices
    }
    return "1.1rem"; // Default font size for larger screens
  };

  const resultTable = urls.map((url: string, index: number) => (
    <tr key={url}>
      <td style={{ wordBreak: "break-word", color: "white", fontFamily: "Raleway" }}>{keywords[index]}</td>
      <td style={{ wordBreak: "break-word", fontFamily: "Raleway" }}>
        <a href={url} target="_blank" rel="noopener noreferrer" style={{ color: "#46a9fa" }}>
          {url}
        </a>
      </td>
    </tr>
  ));


  const briefTable = generatedBriefs.map((item: GeneratedBrief, index: number) => (
    <tr key={item.keyword}>
      <td style={{ wordBreak: "break-word", color: "white", fontFamily: "Raleway" }}>{item.keyword}</td>
      <td><Button variant="gradient"
        gradient={{ from: "white", to: "#FFFC15", deg: 60 }} style={{ color: "black" }} onClick={() => {
          setCurrentBriefIndex(index);
          setOpenBriefModal(true);
          setBriefTextareaValue(generatedBriefs[index].brief)
        }}>View Brief</Button></td>
    </tr>
  ));

  return (
    <div
      style={{
        color: "white",
        fontFamily: "Raleway",
      }}
    >
      <MantineProvider
        theme={{
          components: {
            Text: {
              defaultProps: {
                fontFamily: "Raleway",
                color: "white",
              },
            },
            Table: {
              defaultProps: {
                fontFamily: "Raleway",
                color: "white",
              },
            },
            Radio: {
              defaultProps: {
                fontFamily: "Raleway",
                color: "white",
              },
            },
          },
        }}
      >
        {openAlert ? (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Error!"
            color="red"
          >
            An error occured, please try again!
          </Alert>
        ) : (
          <div></div>
        )}
        {showPage ? (
          <>
            <Modal
              opened={openModal}
              onClose={() => { }}
              withCloseButton={false}
              centered
              padding={20}
              withOverlay={true}
              overlayProps={{
                color: 'gray',
                opacity: 0.5,
                blur: 2,
              }}
              closeOnEscape={false}
              closeOnClickOutside={false}
              withinPortal={true}
            >
              <Grid align="center">
                <Grid.Col span={12}>
                  <Text style={{ fontFamily: "Raleway", color: "black" }}>
                    Your contents are being created. This may take a few
                    minutes...
                  </Text>
                </Grid.Col>
              </Grid>
              <Grid align="center" justify="center">
                <Loader />
              </Grid>
            </Modal>


            <Modal
              opened={openBriefModal}
              onClose={() => setOpenBriefModal(false)}
              centered
              title={<Text style={{ fontSize: '24px', fontWeight: 'bold', color: "black" }}>View Content Brief</Text>}
              padding={20}
              size={"lg"}
            >
              <Grid>
                <Grid.Col span={12}>
                  {currentBriefIndex >= 0 ? (<Flex>
                    <Text weight={"bold"} color="black">Keyword:</Text>
                    <Space w="xs" />
                    <Text color="black">
                      {generatedBriefs[currentBriefIndex].keyword}
                    </Text>
                  </Flex>) : (<></>)}
                  <Space h="md" />
                  {currentBriefIndex >= 0 ? (<Textarea value={briefTextareaValue} onChange={(event) =>
                    setBriefTextareaValue(event.currentTarget.value)
                  } styles={{
                    input: {
                      height: '500px'
                    },
                  }}>
                    {generatedBriefs[currentBriefIndex].brief}
                  </Textarea>) : (<></>)}

                  <Space h="md" />
                  <Flex>
                    <Button style={{ background: "gray" }} onClick={() => {
                      setOpenBriefModal(false);
                    }}>Close</Button>
                    <Space w="md" />
                    <Button onClick={() => {
                      setGeneratedBriefs((prevArray: GeneratedBrief[]) => {
                        const newArray = [...prevArray];
                        newArray[currentBriefIndex].brief = briefTextareaValue;
                        return newArray;
                      });
                      setOpenBriefModal(false);
                    }}>Save</Button>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Modal>

            <Grid justify="center" align="center">
              <Grid.Col span={8}>
                <Title
                  style={{
                    marginBottom: "1rem",
                    marginTop: "1rem",
                    textAlign: "center",
                    fontSize: getFontSize(),
                    color: "white",
                    fontFamily: "Raleway",
                  }}
                >
                  Unlock the power of AI-Written Articles
                </Title>
              </Grid.Col>
            </Grid>
            <Grid justify="center" align="center">
              <Grid.Col span={8}>
                <Text
                  style={{
                    color: "white",
                    fontFamily: "Raleway",
                    marginBottom: "1rem",
                    textAlign: "center",
                    fontSize: getExplanationSize(),
                  }}
                >
                  Our platform leverages artificial intelligence to produce
                  captivating, high-quality content for businesses and content
                  creators. Experience the future of content creation today.
                </Text>
              </Grid.Col>
              <Grid.Col span={10}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Group>
                    <IconCircleCheckFilled color="black" size={20} />
                    <Text style={{ fontSize: getAdvantagesSize() }}>
                      5,000 words with high quality{" "}
                    </Text>
                  </Group>
                </div>
                <Space h={"md"} />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Group>
                    <IconCircleCheckFilled color="black" size={20} />
                    <Text style={{ fontSize: getAdvantagesSize() }}>
                      Image Generation
                    </Text>
                  </Group>
                </div>
                <Space h={"md"} />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Group>
                    <IconCircleCheckFilled color="black" size={20} />
                    <Text style={{ fontSize: getAdvantagesSize() }}>
                      Bulk Content Creation
                    </Text>
                  </Group>
                </div>
                <Space h={"md"} />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Group>
                    <IconCircleCheckFilled color="black" size={20} />
                    <Text style={{ fontSize: getAdvantagesSize() }}>
                      Supported by GPT-4o and GPT-4o-mini
                    </Text>
                  </Group>
                </div>
                <Space h={"md"} />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Group>
                    <IconCircleCheckFilled color="black" size={20} />
                    <Text style={{ fontSize: getAdvantagesSize() }}>
                      Powered by Frase
                    </Text>
                  </Group>
                </div>
              </Grid.Col>
              <Space h="md" />
              <Grid.Col span={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Card
                    style={{
                      width: getContentWidth(),
                      alignItems: "center",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Grid justify="center">
                      <Grid.Col span={12}>
                        <Paper style={{ backgroundColor: "transparent" }}>
                          <Select
                            placeholder="Select GPT model"
                            label="Model:"
                            labelProps={{
                              style: {
                                fontFamily: "Raleway",
                                color: "white",
                                fontWeight: "bold",
                              },
                            }}
                            data={[
                              { value: "GPT-4o-mini", label: "GPT-4o-mini" },
                              { value: "GPT-4o", label: "GPT-4o" },
                            ]}
                            nothingFound="No model found"
                            value={model}
                            onChange={handleModelChange}
                          />
                        </Paper>
                      </Grid.Col>
                      <Space h="md" />
                      <Grid.Col span={12}>
                        <Paper style={{ backgroundColor: "transparent" }}>
                          <Select
                            placeholder="Select your language"
                            label="Language:"
                            labelProps={{
                              style: {
                                fontFamily: "Raleway",
                                color: "white",
                                fontWeight: "bold",
                              },
                            }}
                            data={[
                              { value: "English", label: "English" },
                              { value: "Turkish", label: "Turkish" },
                              { value: "German", label: "German" },
                              { value: "Spanish", label: "Spanish" },
                              { value: "French", label: "French" },
                              { value: "Italian", label: "Italian" },
                            ]}
                            nothingFound="No model found"
                            value={language}
                            onChange={handleLanguageChange}
                          />
                        </Paper>
                      </Grid.Col>
                      <Space h="md" />
                      <Grid.Col span={12}>
                        <Paper style={{ backgroundColor: "transparent" }}>
                          <Select
                            placeholder="Select your tone"
                            label="Tone:"
                            labelProps={{
                              style: {
                                fontFamily: "Raleway",
                                color: "white",
                                fontWeight: "bold",
                              },
                            }}
                            data={[
                              { value: "Formal", label: "Formal" },
                              { value: "Informal", label: "Informal" },
                              { value: "Friendly", label: "Friendly" },
                              { value: "Humorous", label: "Humorous" },
                              {
                                value: "Conversational",
                                label: "Conversational",
                              },
                            ]}
                            nothingFound="No model found"
                            value={tone}
                            onChange={handleToneChange}
                          />
                        </Paper>
                      </Grid.Col>
                      <Space h="md" />
                      <Grid.Col span={12}>
                        <Paper style={{ backgroundColor: "transparent" }}>
                          <TextInput
                            placeholder="Select maximum word count"
                            label="Max Word Count:"
                            labelProps={{
                              style: {
                                fontFamily: "Raleway",
                                color: "white",
                                fontWeight: "bold",
                              },
                            }}
                            type="number"
                            value={wordCount}
                            onChange={(event) => {
                              const input = event.currentTarget.value.replace(
                                /,/g,
                                ""
                              );
                              const number = Number(input);
                              if (!isNaN(number) && number <= 5000) {
                                setWordCount(input);
                              }
                            }}
                          />
                          <Text italic size={"sm"}>
                            You can generate a maximum of 5,000 words of
                            content.
                          </Text>
                        </Paper>
                      </Grid.Col>
                      <Space h={"md"} />
                      <Grid.Col span={12}>
                        <Paper style={{ backgroundColor: "transparent" }}>
                          <Flex>
                            <TextInput
                              style={{ width: "100%" }}
                              label="Topic (Press Enter to add):"
                              labelProps={{
                                style: {
                                  fontFamily: "Raleway",
                                  color: "white",
                                  fontWeight: "bold",
                                },
                              }}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  handleReturnPressed();
                                }
                              }}
                              placeholder="Best content marketing strategies"
                              onChange={(event) =>
                                handleSingleKeywordChange(
                                  event.currentTarget.value
                                )
                              }
                              value={singleKeyword}
                              error={
                                singleKeyword.length < 3 ||
                                keywords.includes(singleKeyword)
                              }
                            />
                            <Space w={"md"} />
                            <Button style={{ alignSelf: "end", color: "black" }} onClick={handleReturnPressed} variant="gradient"
                              gradient={{ from: "white", to: "#FFFC15", deg: 60 }} >Add</Button>
                          </Flex>
                        </Paper>
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <>
                          {keywords.map((keyword) => (
                            <>
                              <Textarea
                                autosize
                                disabled
                                style={{ width: "100%" }}
                                styles={{
                                  input: {
                                    border: "2px solid #cccccc",
                                    borderRadius: 0,
                                    WebkitTextFillColor: "black",
                                    fontFamily: "Raleway",
                                    color: "white",
                                  },
                                }}
                                rightSection={
                                  <CloseButton
                                    aria-label="Remove tag"
                                    onClick={() => handleClosePressed(keyword)}
                                  />
                                }
                              >
                                {keyword}
                              </Textarea>
                              <Space h="sm" />
                            </>
                          ))}
                        </>
                      </Grid.Col>
                      <Space h="md" />
                      <Grid.Col span={12}>
                        <Text
                          style={{
                            fontFamily: "Raleway",
                            color: "white",
                            fontWeight: "bold",
                          }}
                        >
                          Select your content training method
                        </Text>
                        <Group mt="xs" style={{ flexDirection: "column", alignItems: "baseline", display: "flex" }}>
                          <Checkbox
                            checked={checkedValues.projectFiles}
                            onChange={() => handleCheckboxChange("projectFiles")}
                            label={
                              <Text
                                style={{
                                  fontFamily: "Raleway",
                                  color: "white",
                                }}
                              >
                                Use your project's files
                              </Text>
                            }
                          />
                          {checkedValues.projectFiles && (
                            <Grid.Col span={12}>
                              <Flex>
                                <Paper style={{ backgroundColor: "transparent" }}>
                                  <Select
                                    data={projects}
                                    nothingFound="No project found"
                                    value={projectName}
                                    onChange={handleProjectChange}
                                  />
                                </Paper>
                                <Space w={"md"} />
                                <Button
                                  style={{ alignSelf: "end", color: "black" }}
                                  onClick={handleAddProjectPressed}
                                  variant="gradient"
                                  gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                                >
                                  Add Project
                                </Button>
                              </Flex>
                            </Grid.Col>
                          )}
                          <Checkbox
                            checked={checkedValues.googleTopResults}
                            onChange={() => handleCheckboxChange("googleTopResults")}
                            label={
                              <Text
                                style={{
                                  fontFamily: "Raleway",
                                  color: "white",
                                }}
                              >
                                Use Google Top Results to train the content
                              </Text>
                            }
                          />
                          <Checkbox
                            checked={checkedValues.usersURL}
                            onChange={() => handleCheckboxChange("usersURL")}
                            label={
                              <Text
                                style={{
                                  fontFamily: "Raleway",
                                  color: "white",
                                }}
                              >
                                Enter website URL's to train the content
                              </Text>
                            }
                          />
                        </Group>
                      </Grid.Col>
                      {checkedValues.usersURL ? (
                        <>
                          {keywords.length === 0 ? (
                            <Grid.Col span={12}>
                              <Text italic size={"sm"} align="center">
                                You need to add keywords first!
                              </Text>
                            </Grid.Col>
                          ) : (
                            <Grid.Col span={12}>
                              {keywords.map((keyword) => (
                                <>
                                  <Grid.Col span={12}>
                                    <Textarea
                                      label={`Content training URLs for the keyword: ${keyword}`}
                                      labelProps={{
                                        style: {
                                          fontFamily: "Raleway",
                                          color: "white",
                                          fontWeight: "bold",
                                        },
                                      }}
                                      minRows={6}
                                      placeholder="Enter your URLs seperated by new line."
                                      onChange={(event) =>
                                        handleSingleKeywordContentTrainingURLsChange(
                                          event.currentTarget.value,
                                          keyword
                                        )
                                      }
                                      value={
                                        contentTrainingURLs[
                                        keywords.indexOf(keyword)
                                        ]
                                      }
                                    />
                                  </Grid.Col>
                                  <Space h="sm" />
                                </>
                              ))}
                            </Grid.Col>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {generatedBriefs.length > 0 ? (
                        <Grid.Col span={12}>
                          <Space h="sm" />
                          <Table
                            ref={tableRef}
                            style={{ tableLayout: "fixed" }}
                          >
                            <colgroup>
                              <col style={{ width: "50%" }} />
                              <col style={{ width: "50%" }} />
                            </colgroup>
                            <thead>
                              <tr>
                                <th style={{ wordBreak: "break-word", fontFamily: "Raleway", color: "white" }}>
                                  Keyword
                                </th>
                                <th style={{ wordBreak: "break-word", fontFamily: "Raleway", color: "white" }}>
                                  Brief
                                </th>
                              </tr>
                            </thead>
                            <tbody>{briefTable}</tbody>
                          </Table>
                          <Space h="md" />

                        </Grid.Col>
                      ) : (
                        <Space h="md" />
                      )}
                      <div style={{
                        cursor: keywords.length === 0 ||
                          (contentTrainingURLs.filter(
                            (item) => item.length === 0
                          ).length > 0 &&
                            contentTrainingMethod === "usersURL") ||
                          (contentTrainingURLs.length !== keywords.length &&
                            contentTrainingMethod === "usersURL") ? 'not-allowed' : 'pointer',
                        marginTop: "15px"
                      }}>
                        <Grid.Col span={4} style={{ padding: 0 }}>
                          <Button
                            styles={{
                              root: {
                                color: 'black',
                                fontFamily: 'Raleway',
                              },
                            }}
                            type="submit"
                            variant="gradient"
                            gradient={{ from: "white", to: "#FFFC15", deg: 60 }}
                            onClick={generatedBriefs.length == 0 ? handleGenerateBrief : handleGenerateContent}
                            disabled={
                              keywords.length === 0 ||
                              (contentTrainingMethod === "projectFiles" && projectName.length === 0) ||
                              (contentTrainingURLs.filter(
                                (item) => item.length === 0
                              ).length > 0 &&
                                contentTrainingMethod === "usersURL") ||
                              (contentTrainingURLs.length !== keywords.length &&
                                contentTrainingMethod === "usersURL")
                            }
                          >
                            {generatedBriefs.length == 0 ? "Generate Brief" : "Generate Content"}
                          </Button>
                        </Grid.Col>
                      </div>
                      {urls.length > 0 ? (
                        <Grid.Col span={12}>
                          <Space h="sm" />
                          <Table
                            ref={tableRef}
                            style={{ tableLayout: "fixed" }}
                          >
                            <colgroup>
                              <col style={{ width: "50%" }} />
                              <col style={{ width: "50%" }} />
                            </colgroup>
                            <thead>
                              <tr>
                                <th style={{ wordBreak: "break-word", fontFamily: "Raleway", color: "white" }}>
                                  Keyword
                                </th>
                                <th style={{ wordBreak: "break-word", fontFamily: "Raleway", color: "white" }}>
                                  Generated URL
                                </th>
                              </tr>
                            </thead>
                            <tbody>{resultTable}</tbody>
                          </Table>
                          <Space h="md" />
                        </Grid.Col>
                      ) : (
                        <Space h="md" />
                      )}
                    </Grid>
                  </Card>
                </div>
              </Grid.Col>
            </Grid>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: window.innerWidth > 724 ? "100px" : "0px",
            }}
          >
            <Loader />
          </div>
        )}
      </MantineProvider>
    </div>
  );
};

export default HomePage;
